import React, { useState, useEffect, useRef } from 'react';
import Header from 'src/Layout/HomeLayout/Header';
import { Box, Grid, Typography, Tabs, Tab, Divider } from '@mui/material';
import SearchBar from 'src/Component/SearchBar';
import PrivateFeed from './PrivateFeed';
import PublicFeed from './PublicFeed';
import GroupFeed from './GroupFeed';
import styles from './index.module.scss';

import { useGetSpecialtyListQuery  } from 'src/api/createFeed';
import {useGetGroupNamesQuery} from 'src/api/feed';
import { Speciality } from 'src/api/createFeed/types';
import SpecialityModal from './FilterModal';
import { Specialty } from 'src/api/feed/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { setSelectedSpecialitiesFilter, setSortBy } from 'src/store/features/common/commonSlice';

const Home = () => {
  const [activeTab, setActiveTab] = useState<0 | 1 | 2>(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab !== null ? parseInt(savedTab, 10) as 0 | 1 : 0;
  });

  const { data: groupNamesData, error: groupNamesError, isLoading: isGroupNamesLoading } = useGetGroupNamesQuery();
  
  useEffect(() => {
    localStorage.setItem('activeTab', activeTab.toString());
  }, [activeTab]);
  
  const [searchValue, setSearchValue] = useState<string>('');

  const [selectedSpecialities, setSelectedSpecialities] = useState<Specialty[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const {selectedSpecialitiesFilter, sortBy} = useSelector((state: RootState) => state.common);

  const { data: fetchedSpecialities = [], isLoading, isError } = useGetSpecialtyListQuery('');

  useEffect(() => {
    if (!isLoading && !isError) {
      setSelectedSpecialities(fetchedSpecialities);
    }
  }, [fetchedSpecialities, isLoading, isError]);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleApply = (specialities: Speciality[], sortByFilter: string) => {
   dispatch(setSelectedSpecialitiesFilter(specialities))
    dispatch(setSortBy(sortByFilter))
  };

  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    setActiveTab(newValue);
  };

  
  const tabs = ['Public', 'Private'];
  if (groupNamesData && Array.isArray(groupNamesData) && groupNamesData.length > 0) {
    tabs.push('Group');
  }

  const [selectedFocusArea, setSelectedFocusArea] = useState([]);
  useEffect(() => {
    const focusArea = selectedSpecialitiesFilter.flatMap(speciality => speciality.sub_specialities);
    setSelectedFocusArea(focusArea);
  }, [selectedSpecialitiesFilter]);

  const filterBoxRef = useRef(null);
  const [filterBoxHeight, setFilterBoxHeight] = useState(0);

  useEffect(() => {
    if (filterBoxRef.current) {
      setFilterBoxHeight(filterBoxRef.current.offsetHeight);
    }else{
      setFilterBoxHeight(0);
    }
  }, [selectedSpecialitiesFilter, selectedFocusArea]);

  const groupName = isGroupNamesLoading
    ? 'Loading...'
    : groupNamesError
    ? 'Error loading group name'
    : groupNamesData && Array.isArray(groupNamesData) && groupNamesData.length > 0
    ? groupNamesData[0].name 
    : 'No group found';

  return (
    <Box>
      <Header>
        <Box width="100%" p={3} pb={0}>
          <Grid container justifyContent="center">
            <Box className={styles.wrapper}>
              <SearchBar
                isFilterApplied={selectedSpecialitiesFilter.length > 0 || sortBy.length > 0 && sortBy !== 'most_recent'}
                onChangeWithDebounce={setSearchValue}
                onFilterClick={handleOpenModal}
              />
            </Box>
          </Grid>
        </Box>
        <Box width="100%" pr={3} pl={3}>
          <Box mt={1}>
            <Typography variant="subtitle1" fontSize="24px">
              Feed
            </Typography>
          </Box>

          <SpecialityModal
            open={modalOpen}
            onClose={handleCloseModal}
            onApply={handleApply}
            specialities={selectedSpecialities}
            appliedSpecialty={selectedSpecialitiesFilter}
            appliedSortBy={sortBy}
          />

          <Grid container justifyContent="center">
            <Box className={styles.wrapper}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
                variant="fullWidth"
                textColor="primary"
                indicatorColor="secondary"
              >
                {tabs.map((tab, i) => {
                  if (tab === 'Group') {
                    return <Tab key={tab} label={groupName} sx={{ color: activeTab === i ? 'white' : '#909090' }} />;
                  }
                  return <Tab key={tab} label={tab} sx={{ color: activeTab === i ? 'white' : '#909090' }} />;
                })}
              </Tabs>
              <Divider />
            </Box>
          </Grid>
        </Box>
      </Header>
      <Box>
        <Grid container mt={12} justifyContent="center">
          {selectedSpecialitiesFilter.length > 0 && (
            <Box
              ref={filterBoxRef}
              className={styles.specialtyContainer}
            >
              <Typography variant="subtitle1">
                <span style={{ marginRight: '8px', fontWeight: 'bold' }}>{'Subspecialty Channel: '}</span>
                <span style={{ fontWeight: 600 }}>
                  {selectedSpecialitiesFilter.map(speciality => {
                    return <a key={speciality.id}> #{speciality.name} </a>;
                  })}
                </span>
              </Typography>
              {selectedFocusArea.length > 0 && (
                <Typography variant="subtitle1">
                  <span style={{ marginRight: '8px', fontWeight: 'bold' }}>{'Focus Area: '}</span>
                  <span style={{ fontWeight: 600 }}>
                    {selectedFocusArea.map(sub => (
                      <a key={sub.id}> #{sub.name} </a>
                    ))}
                  </span>
                </Typography>
              )}
            </Box>
          )}
          <Box
            sx={{ marginTop: `${filterBoxHeight}px` }}
            className={styles.wrapper}
          >
            {/* 6️⃣ Conditionally render based on activeTab */}
            {activeTab === 0 ? (
              <PublicFeed
                searchValue={searchValue}
                specialities={selectedSpecialitiesFilter}
                sortBy={sortBy}
              />
            ) : activeTab === 1 ? (
              <PrivateFeed
                searchValue={searchValue}
                specialities={selectedSpecialitiesFilter}
                sortBy={sortBy}
              />
            ) : (
              /* 7️⃣ Render GroupFeed if activeTab is 2 */
              <GroupFeed
                searchValue={searchValue}
                specialities={selectedSpecialitiesFilter}
                sortBy={sortBy}
              />
            )}
          </Box>
          
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
