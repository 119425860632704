import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'src/api/axiosBaseQuery'
import { API_BASE_PATH, PER_PAGE } from 'src/utils/constants'
import {
  AddCommentPayload,
  CommentReplyResponse,
  CommentReplyResponseData,
  CommentResponseData,
  CommentsResponse,
  Feed,
  FeedResponse,
  FeedResponseData,
  PaginationPayload,
} from './types'

const feedApi = createApi({
  reducerPath: 'feedApi',
  keepUnusedDataFor: 0,
  baseQuery: axiosBaseQuery({
    baseUrl: API_BASE_PATH,
  }),
  tagTypes: ['Feed'],
  endpoints: (builder) => ({
    getPrivateFeed: builder.query<FeedResponseData, PaginationPayload>({

    query: ({ page, perPage = PER_PAGE, search = '', speciality = [], sortOrder = '' }) => {
      // Determine the ordering based on the sortOrder value
      let ordering = '';
      switch (sortOrder) {
        case 'likes':
          ordering = '-like_count';
          break;
        case 'dislikes':
          ordering = '-dislike_count';
          break;
        case 'most_recent':
          ordering = '-created_at';
          break;
        default:
          ordering = '';
      }

      const subspecialityParams = speciality
      .flatMap(s => s.sub_specialities)
      .map(sub => `sub_speciality=${encodeURIComponent(sub.name)}`)
      .join('&');

      const specialityParams = speciality.map(s => {
        if(s.sub_specialities.length > 0) return
        return `speciality=${encodeURIComponent(s.name)}`
      }).join('&');
  
      // // Extract the names from the speciality array
      // const specialityParams = speciality.map(s => `speciality=${encodeURIComponent(s.name)}`).join('&');
      // const subspecialityParams = speciality
      //   .flatMap(s => s.sub_specialities)
      //   .map(sub => `subspeciality=${encodeURIComponent(sub.name)}`)
      //   .join('&');
  
      // Build the full query URL
      const queryParams = [
        `page_size=${perPage}`,
        `page=${page}`,
        `search=${search}`,
        ordering ? `ordering=${ordering}` : '',
        subspecialityParams,
        specialityParams,
      ].filter(Boolean).join('&');
  
      return {
        url: `/api/v1/feed/private_feed/?${queryParams}`,
        method: 'GET',
      };
    },
    transformResponse: (response: FeedResponse) => {
      return response.data;
    },
  }),

    getPublicFeed: builder.query<FeedResponseData, PaginationPayload>({
      query: ({ page, perPage = PER_PAGE, search = '', speciality = [], sortOrder = '' }) => {
        // Determine the ordering based on the sortOrder value
        let ordering = '';
        switch (sortOrder) {
          case 'likes':
            ordering = '-like_count';
            break;
          case 'dislikes':
            ordering = '-dislike_count';
            break;
          case 'most_recent':
            ordering = '-created_at';
            break;
          default:
            ordering = '';
        }
    
        // Extract the names from the speciality array
       
        const subspecialityParams = speciality
          .flatMap(s => s.sub_specialities)
          .map(sub => `sub_speciality=${encodeURIComponent(sub.name)}`)
          .join('&');

          const specialityParams = speciality.map(s => {
            if(s.sub_specialities.length > 0) return
            return `speciality=${encodeURIComponent(s.name)}`
          }).join('&');
    
        // Build the full query URL
        const queryParams = [
          `page_size=${perPage}`,
          `page=${page}`,
          `search=${search}`,
          ordering ? `ordering=${ordering}` : '',
          subspecialityParams,
          specialityParams
        ].filter(Boolean).join('&');
    
        return {
          url: `/api/v1/feed/public_feed/?${queryParams}`,
          method: 'GET',
        };
      },
      transformResponse: (response: FeedResponse) => {
        return response.data;
      },
    }),

    getGroupFeed: builder.query<FeedResponseData, PaginationPayload>({
      query: ({ page, perPage = PER_PAGE, search = '', speciality = [], sortOrder = '' }) => {
        // Determine the ordering based on the sortOrder value
        let ordering = '';
        switch (sortOrder) {
          case 'likes':
            ordering = '-like_count';
            break;
          case 'dislikes':
            ordering = '-dislike_count';
            break;
          case 'most_recent':
            ordering = '-created_at';
            break;
          default:
            ordering = '';
        }
    
        // Extract the names from the speciality array
        const specialityParams = speciality.map(s => `speciality=${encodeURIComponent(s.name)}`).join('&');
        const subspecialityParams = speciality
          .flatMap(s => s.sub_specialities)
          .map(sub => `sub_speciality=${encodeURIComponent(sub.name)}`)
          .join('&');
    
        // Build the full query URL
        const queryParams = [
          `page_size=${perPage}`,
          `page=${page}`,
          `search=${search}`,
          ordering ? `ordering=${ordering}` : '',
          subspecialityParams,
          specialityParams
        ].filter(Boolean).join('&');
    
        return {
          url: `/api/v1/group/list_group_posts/?${queryParams}`,
          method: 'GET',
        };
      },
      transformResponse: (response: FeedResponse) => {
        return response.data;
      },

    }),

    getGroupNames: builder.query<FeedResponseData, void>({
      query: () => ({
        url: '/api/v1/group/group_name/',
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),
    
    addComment: builder.mutation<void, { postId: number; payload: FormData }>({
      query: ({ postId, payload }) => ({
        url: `/api/v1/post/${postId}/add_comment/`,
        method: 'POST',
        data: payload,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }),
    }),
    addAnonymousComment: builder.mutation<void, { hashId: string; payload: any }>({
      query: ({ hashId, payload }) => ({
        url: `/api/v1/deep-link/post_comment/?hashid=${hashId}`,
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    }),
    listComments: builder.query<CommentResponseData, number>({
      query: (postId) => ({
        url: `/api/v1/post/${postId}/list_comments/`,
        method: 'GET',
      }),
      transformResponse: (response: CommentsResponse) => {
        return response.data
      },
    }),
    postView: builder.query<CommentResponseData, any>({
      query: (hashId) => ({
        url: `/api/v1/deep-link/?hashid=${hashId}`,
        method: 'GET',
      }),
      transformResponse: (response: CommentsResponse) => {
        return response.data
      },
    }),
    deleteMyComment: builder.mutation<void, number>({
      query: (commentId) => ({
        url: `/api/v1/post/${commentId}/delete_comment/`,
        method: 'DELETE',
      }),
    }),
    addCommentReply: builder.mutation<void, { commentId: number; payload: FormData }>({
      query: ({ commentId, payload }) => ({
        url: `/api/v1/post/${commentId}/add_reply/`,
        method: 'POST',
        data: payload,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      }),
    }),

    listCommentReplies: builder.query<CommentReplyResponseData, number>({
      query: (commentId) => ({
        url: `/api/v1/post/${commentId}/list_all_replies/`,
        method: 'GET',
      }),
      transformResponse: (response: CommentReplyResponse) => {
        return response.data
      },
    }),

    togglePostLike: builder.mutation<void, number>({
      query: (postId) => ({
        url: '/api/v1/reaction/',
        method: 'POST',
        data: {
          post: postId,
        },
      }),
    }),

    postLike: builder.mutation<void, number>({
      query: (postId) => ({
        url: '/api/v1/post/',
        method: 'POST',
        data: {
          post: postId,
        },
      }),
    }),

    postDislike: builder.mutation<void, number>({
      query: (postId) => ({
        url: '/api/v1/post/',
        method: 'POST',
        data: {
          post: postId,
        },
      }),
    }),

    toggleCommentLike: builder.mutation<void, number>({
      query: (commentId) => ({
        url: `/api/v1/post/${commentId}/like-comment/`,
        method: 'POST',
      }),
    }),

    saveFeed: builder.mutation<void, number>({
      query: (postId) => ({
        url: '/api/v1/savepost/save/',
        method: 'POST',
        data: {
          // eslint-disable-next-line camelcase
          post_id: postId,
        },
      }),
    }),

    getPrivateFavFeed: builder.query<FeedResponseData, void>({
      query: () => ({
        url: '/api/v1/feed/saved_private/',
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),
    getPublicFavFeed: builder.query<FeedResponseData, void>({
      query: () => ({
        url: '/api/v1/feed/saved_public/',
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),

    getMyPrivateFeeds: builder.query<FeedResponseData, PaginationPayload>({
      query: ({ page, perPage = PER_PAGE, search = '' }) => ({
        url: `/api/v1/feed/my_feed_private/?page_size=${perPage}&page=${page}&search=${search}`,
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),

    getMyPublicFeeds: builder.query<FeedResponseData, PaginationPayload>({
      query: ({ page, perPage = PER_PAGE, search = '' }) => ({
        url: `/api/v1/feed/my_feed_public/?page_size=${perPage}&page=${page}&search=${search}`,
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),
    
    getMyGroupFeeds: builder.query<FeedResponseData, PaginationPayload>({
      query: ({ page, perPage = PER_PAGE, search = '' }) => ({
        url: `/api/v1/group/my_feed_group/?page_size=${perPage}&page=${page}&search=${search}`,
        method: 'GET',
      }),
      transformResponse: (response: FeedResponse) => {
        return response.data
      },
    }),

    getFeedComments: builder.query<Feed, string>({
      query: (feedId) => ({
        url: `/api/v1/post/${feedId}/list_comments/`,
        method: 'GET',
      }),
      transformResponse: (response: Feed) => {
        return response
      },
    }),
  }),
})

export const {
  useLazyGetPrivateFeedQuery,
  useLazyGetPublicFeedQuery,
  useLazyGetGroupFeedQuery,
  useGetGroupNamesQuery,
  useAddCommentMutation,
  useAddAnonymousCommentMutation,
  useListCommentsQuery,
  usePostViewQuery,
  useDeleteMyCommentMutation,
  useAddCommentReplyMutation,
  useListCommentRepliesQuery,
  useTogglePostLikeMutation,
  usePostLikeMutation,
  usePostDislikeMutation,
  useToggleCommentLikeMutation,
  useSaveFeedMutation,
  useGetPublicFavFeedQuery,
  useGetPrivateFavFeedQuery,
  useLazyGetMyPrivateFeedsQuery,
  useLazyGetMyPublicFeedsQuery,
  useLazyGetMyGroupFeedsQuery,
  useGetFeedCommentsQuery
} = feedApi
export default feedApi
