import React, { useEffect, useState } from 'react'
import Header from 'src/Layout/HomeLayout/Header'
import { Box, Grid, Typography, Tabs, Tab, Divider } from '@mui/material'
import SearchBar from 'src/Component/SearchBar'
import PrivateFeedList from './PrivateFeedList'
import PublicFeedList from './PublicFeedList'
import GroupFeedList from './GroupFeedList'
import { useGetGroupNamesQuery } from 'src/api/feed';

import styles from './index.module.scss'

const MyPosts = () => {

  const [activeTab, setActiveTab] = useState<0 | 1 | 2>(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab !== null ? parseInt(savedTab, 10) as 0 | 1 : 0;
  });

  const { data: groupNamesData, error: groupNamesError, isLoading: isGroupNamesLoading } = useGetGroupNamesQuery();
    
  useEffect(() => {
    localStorage.setItem('activeTab', activeTab.toString());
  }, [activeTab]);
  const [searchValue, setSearchValue] = useState<string>('')

  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    setActiveTab(newValue)
  }
  const tabs = ['Public', 'Private'];
  if (groupNamesData && Array.isArray(groupNamesData) && groupNamesData.length > 0) {
    tabs.push('Group');
  }
  const groupName = isGroupNamesLoading
    ? 'Loading...'
    : groupNamesError
    ? 'Error loading group name'
    : groupNamesData && Array.isArray(groupNamesData) && groupNamesData.length > 0
    ? groupNamesData[0].name 
    : 'No group found';


  return (
    <Box>
      <Header>
        <Box width='100%' p={3} pb={0}>
          <Grid container justifyContent='center'>
            <Box className={styles.wrapper}>
              <SearchBar onChangeWithDebounce={setSearchValue} />
            </Box>
          </Grid>
        </Box>

        <Box width='100%' pr={3} pl={3}>
          <Box mt={1}>
            <Typography variant='subtitle1' fontSize='24px'>
              My Posts
            </Typography>
          </Box>

          <Grid container justifyContent='center'>
            <Box className={styles.wrapper}>
              <Tabs
                value={activeTab}
                onChange={handleChange}
                centered
                variant='fullWidth'
                textColor='primary'
                indicatorColor='secondary'
              >
                {tabs.map((tab, i) => {
                  if (tab === 'Group') {
                      return <Tab key={tab} label={groupName} sx={{ color: activeTab === i ? 'white' : '#909090' }} />;
                    }
                  return (
                    <Tab
                      key={tab}
                      label={tab}
                      sx={{ color: activeTab === i ? 'white' : '#909090' }}
                    />
                  )
                })}
              </Tabs>
              <Divider />
            </Box>
          </Grid>
        </Box>
      </Header>

      
      <Box>
        <Grid container mt={12} justifyContent='center'>
          <Box className={styles.wrapper}>
            {activeTab === 0 ? (
              <PublicFeedList
                searchValue={searchValue}
              />
            ) : activeTab === 1 ? (
              <PrivateFeedList
                searchValue={searchValue}
              />
            ) : (
              /* 7️⃣ Render GroupFeed if activeTab is 2 */
              <GroupFeedList
                searchValue={searchValue}
              />
            )}
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default MyPosts
